export const DEFAULT_PAGE_TITLE = "Seedkit";
export const DEFAULT_PAGE_DESCRIPTION = "Seedkit (Social Enterprise Evaluation & Data Platform)";

export const LOGIN_COOKIE_KEY = "sklogin";

export const EXPANDED_NAV_WIDTH = "w-64";
export const COLLAPSED_NAV_WIDTH = "w-17";

export const EXPANDED_NAV_MARGIN = "ml-64";
export const COLLAPSED_NAV_MARGIN = "ml-17";

export const CONTEXT_STORAGE_KEY = "skcontext";

export const LOGIN_ERROR = "There was an error logging you in. Please try again later.";
export const NOT_ENABLED_ALERT = "This function is not enabled in the current version of Seedkit";

export const ORG_NAME_HELP = "To set, enter a valid number in the ABN field and click the search button.";

export const ContextStatus = {
    OK: "ok",
    LOADING: "loading",
    ERROR: "error"
}

export const CONTEXT_ERROR = "Currently unable to access your organisation's data. Please try again later.";

export const ACCESS_ERROR = "User does not have the required access.";

export const TRIAL_ACCOUNT_TOOLTIP = "You can create a trial account without providing a valid ABN, but you will be unable to export data or dashboards, or create additional users for your organisation";

export const DEBUGGING_BANNER = { 
    text: "1 March 2024: Our team is currently working hard to improve the site and remove bugs. We appreciate your patience and support of Seedkit.", 
    icon: "bug-slash", 
    fill: "#92400E", 
    foreColor: "text-dark-yellow02",
    bgColor: "#FFF6D4"
};

export const GOT_BETTER_BANNER = { 
    text: "8 April 2024: Seedkit just got better. The new updated and improved Dashboard features are live now! While our team continues to work hard to improve the site, we appreciate your patience and support of Seedkit.", 
    icon: "news", 
    fill: "#FFFFFF", 
    foreColor: "text-white",
    bgColor: "#16BA76"
};

export const TRIAL_BANNER = { 
    text: "This is a trial account. To convert to a full account, <br/>click on the button to the right", 
    icon: "cresent-moon", 
    fill: "#1A53E3", 
    foreColor: "text-black",
    bgColor: "#D8E3FF",
    button: { 
        icon: "full-moon",
        fill: "#1A53E3",
        variant: "outline"
    },
    tooltip: "You can get a feel and look at most features and functionality Seedkit has to offer, but you won't be able to import and export data or dashboards from Seedkit, or create members for your organisation while in a trial account."
};